$(document).ready(function () {

    //this is for fixed menu and remove scroll bar 

    $('button.navbar-toggler').click(function () {
        $('.header-container').toggleClass('menuFixed');
        $('body').toggleClass('ScrollOff');
    });

    //this is for fixed menu and remove scroll bar 

    //Scroll to top button

    var scrollToTopBtn = document.querySelector(".scrollToTopBtn");
    var rootElement = document.documentElement;

    function handleScroll() {
        // Do something on scroll
        var scrollTotal = rootElement.scrollHeight - rootElement.clientHeight;
        if (rootElement.scrollTop / scrollTotal > 0.4) {
            // Show button
            scrollToTopBtn.classList.add("showscrollToTopBtn");
        } else {
            // Hide button
            scrollToTopBtn.classList.remove("showscrollToTopBtn");
        }
    }

    function scrollToTop() {
        // Scroll to top logic
        rootElement.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    scrollToTopBtn.addEventListener("click", scrollToTop);
    document.addEventListener("scroll", handleScroll);

    //Scroll to top button

    // Scroll to ID

    $("a[href^='#']").click(function (e) {

        var doScroll = $(this).attr("href") != "#";

        if ($(this).attr("href") == "#memberOnlyAccessDenied") {
            $("#MemberOnlyAccessDenied").modal("show");
        }
        else if (doScroll) {

            var position = $($(this).attr("href")).offset().top;

            rootElement.scrollTo({
                top: position,
                behavior: "smooth"
            });
        }
    });

    // Scroll to ID

    //Add border-bottom for the active menu
    var pathname = "/" + window.location.pathname.split("/")[1];
    $("ul.navbar-nav > li.nav-item > a.nav-link[href='" + pathname + "']").addClass("active");
    //Add border-bottom for the active menu

    //Update Cart link in menu

    updateCartLinkInMenu();

    //Update Cart link in menu

    //Search box

    $(".waw_searchBox input").keydown(function (event) {
        if (event.which === 13) {
            event.preventDefault();

            var keyword = $(this).val();
            var url = window.location.origin;

            if (keyword.trim() != "") {
                url += "/search/Init?q=" + keyword;
            }

            window.location.replace(url);
        }
    });

    //Search box


    // Update URL in back button

    var referrerDomain = document.referrer.split('/')[2];
    if (history.length == 1 || !referrerDomain || !(referrerDomain == $("#AppConfig_AppPaths_WebURL").val().split("/")[2] || referrerDomain == $("#AppConfig_AppPaths_IDPURL").val().split("/")[2])) {
        $(".back-history").each(function () {
            $(this).attr("href", $("#AppConfig_AppPaths_WebURL").val());
        });
    }

    // Update URL in back button

});

//Update cart link in menu

function updateCartLinkInMenu() {
    $('.cart-link-update').each((i, obj) => {

        const BC_CART_ID = localStorage.getItem('bcCartId');
        const HAS_MEMBERSHIP_IN_CART = localStorage.getItem('hasMembershipInCart');
        const CART_LENGTH = localStorage.getItem('cartLength');

        var cartLink = window.location.origin + "/cart?cartId=";

        if (BC_CART_ID) {
            cartLink = cartLink + BC_CART_ID;
        }

        if (HAS_MEMBERSHIP_IN_CART === 'true') {
            cartLink = cartLink + "&membership=true";
        }

        if (BC_CART_ID) {
            $(obj).attr('href', cartLink);
            $(obj).addClass("cart-link-update-contents");
            $(obj).attr("data-after", CART_LENGTH == 0 ? " " : CART_LENGTH);
        } else {
            $(obj).removeClass("cart-link-update-contents");
        }
    });
}

//Update cart link in menu

//Get AccessToken

function getBCAccessToken() {
    const BCClientId = $("#IDPClients_BC_ClientId").val();
    const BCClientScope = $("#IDPClients_BC_Scope").val();

    return getAccessToken(BCClientId, BCClientScope);
}

function getSFAccessToken() {
    const SFClientId = $("#IDPClients_SF_ClientId").val();
    const SFClientScope = $("#IDPClients_SF_Scope").val();

    return getAccessToken(SFClientId, SFClientScope);
}

function getAccessToken(clientId, clientScope) {

    var body = { "clientId": clientId, "clientScope": clientScope };

    return new Promise((resolve) => {
        $.ajax({
            url: '/account/accesstoken',
            type: "POST",
            data: body,
            success: function (data) {
                resolve(data)
            },
            error: function (error) {
                reject(error)
            }
        });
    });
}

//Get AccessToken


const debounce = (mainFunction, delay) => {
    let timer;
    return function (...args) {
        clearTimeout(timer);

        timer = setTimeout(() => {
            mainFunction(...args);
        }, delay);
    };
};